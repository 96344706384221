<template >
  <div>
    <block-title>退貨明細</block-title>

      <providerReturnOrderCard
          v-for="(item, index) in backItems"
          :key="index"
          v-model="backItems[index]"
          :configs="cardConfigs(item, index)"
      ></providerReturnOrderCard>

    <block-title :hasBorder="false">圖片上傳</block-title>
    <photo-selector
      :value="photos"
      multiple
      class="mb-5"
      readonly
    ></photo-selector>

    <v-textarea
      v-model="comment"
      disabled
      name="comment"
      outlined
      class="mb-5"
      label="商家備註"
    ></v-textarea>

    <v-textarea
        v-model="back_note"
        name="back_note"
        :disabled="!editAble"
        outlined
        class="mb-5"
        label="供應商備註"
    ></v-textarea>


    <number-bar
      title="退款合計"
      unit="元"
      :value="total"
      readonly
      type="secondary"
      class="mb-5"
    ></number-bar>

    <input
        v-for="(item,index) in items"
        :key="item.back_item_id"
        type="text"
        hidden=""
        :value="item.back_item_id"
        :name="`items[${index}][id]`"
    />

  </div>
</template>

<script lang="babel" type="text/babel">
import ItemMixin from '@/components/form/custom/ItemMixin.js'
import backOrderItemsMixins from "@/mixins/backOrderItems.js"
import countMixins from "@/mixins/count"
import providerReturnOrderCard from "@/modules/base/components/priceCard/returnOrder/providerReturnOrderCard.vue"

export default {
  mixins: [ItemMixin, backOrderItemsMixins, countMixins],
  components: {
    providerReturnOrderCard
  },
  data: () => ({
    backItems: [],
    back_note: "",
    total: 0
  }),
  computed: {
    backOrderStatusText() {
      return this.props.backOrderStatusText
    },
    status() {
      return this.propsValue.status
    },
    backOrderStatus() {
      return this.propsValue.backOrder_status
    },
    // backItems() {
    //   if(!this.propsValue) return []
    //   return this.propsValue.items.map(item => {
    //     const reasonTarget = this.propsValue.backReason.find(reason => reason.value == item.reason)
    //     return {
    //       ...item,
    //       return_count: this.$helper.reverseShippingCount(item.count,item.shipping_unit),
    //       price: item.order_item_price,
    //       reason: reasonTarget.text,
    //       discount_config: item.discount_config,
    //       shipping: item.shipping,
    //       amount: item.amount
    //     }
    //   })
    // },
    photos() {
      if(!this.propsValue) return []
      return this.propsValue.received_record
    },
    comment() {
      if(!this.propsValue) return null
      return this.propsValue.comment
    },
    editAble() {
      return this.status == 'request_return'
    },
  },
  watch: {
    propsValue: {
      immediate: true,
      deep: true,
      async handler() {
        if(!this.propsValue) return []
        this.backItems = this.propsValue.items.map(item => {
          const reasonTarget = this.propsValue.backReason.find(reason => reason.value == item.reason)
          return {
            ...item,
            return_count: this.$helper.reverseShippingCount(item.count,item.shipping_unit),
            price: Number(item.order_item_price).toFixed(0),
            reason: reasonTarget.text,
            discount_config: item.discount_config,
            shipping: item.shipping,
            amount: item.amount
          }
        })

        this.back_note = this.propsValue.back_note
      }
    },
    backItems: {
      immediate: true,
      deep: true,
      handler() {
        const total = this.backItems.reduce((previousValue, currentValue) => {
          previousValue += +(currentValue.amount)
          return previousValue
        }, 0)

        this.total = Math.round(total)
      }
    }
  },
  methods: {
    cardConfigs(item, index) {
      return [
        {label: item.product, labelColor: "primary--text", colon: false},
        { id: "unit", col: 6 },
        {
          id: "price",
          valueSuffix: "元",
          col: 6
        }, 
        { 
          id: "return_count", 
          label: "退貨數量",  
        },
        {
          label: '退貨原因',
          value: item.reason
        },
        {
          id: 'amount',
          type: 'number-input',
          disabled: !this.editAble,
          label: "退款小計",
          valueSuffix: "元",
          barType: 'gray',
          fixed: 2,
          name: `items[${index}][amount]`,
          max: (+item.price) * (+item.return_count) * (+item.per_shipping_count),
        },
      ]
    },
  },
}
</script>

